import axios from 'axios';
import { API_URL } from '../../constants';
import { AdminUserResDto } from '../admin-user/res/admin-user-res.dto';
import { HandleAxiosErrorResDto, type ErrorResDto } from '../error-res.dto';
import type { PaginatedListQueryReqDto } from '../shared/req/paginated-list-query-req.dto';
import type { PaginatedListResDto } from '../shared/res/paginated-list-res.dto';
import type { PostSuperAdminCoachReqDto } from './req/post-super-admin-coach-req.dto';

export class SuperAdminCoachServer {
  private readonly API_PATH: string = `${API_URL}/super-admin/coach`;

  async get(paginationData: PaginatedListQueryReqDto): Promise<PaginatedListResDto<AdminUserResDto> | ErrorResDto> {
    try {
      const together = { ...paginationData.order, ...paginationData.filters };
      const queryParams = new URLSearchParams(together);
      queryParams.append('limit', (paginationData.limit ?? 10)?.toString());
      queryParams.append('page', (paginationData.page ?? 1).toString());
      const response = await axios.get<PaginatedListResDto<AdminUserResDto>>(`${this.API_PATH}?${queryParams}`);
      return response.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }

  async getById(id: string): Promise<AdminUserResDto | ErrorResDto> {
    try {
      const response = await axios.get<AdminUserResDto>(`${this.API_PATH}/${id}`);
      return response.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }

  async post(data: PostSuperAdminCoachReqDto): Promise<any | ErrorResDto> {
    try {
      const response = await axios.post<any>(`${this.API_PATH}/`, data);
      return response.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }
}
